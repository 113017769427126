@import "../../App";

.project {
  background-color: $darkblue;
  border-radius: 5px;
  padding: 50px;
}

.screenshot {
  max-width: 40vw;
  height: auto;
  max-height: 60vh;
  margin-bottom: 30px;
}

.project-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-description {
  margin: 30px 0 30px 0;
  color: white;
}

.link-button,
.link-button:hover,
.link-button:active,
.link-button:focus {
  background-color: $red;
  border: none;
  outline: none;
  color: white;
}

.link-button:hover {
  opacity: 0.8;
}

@media (max-width: 850px) {
  .project {
    padding: 15px;
  }
  .link-button,
  .project-description {
    margin-top: 10px;
  }
  .screenshot {
    max-width: 100%;
  }
}

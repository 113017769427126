@import "../../App";

#home {
    display: flex;
    flex-direction: column;
    height: 100vh;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: $darkblue;
    color: $yellow;
}

#portrait {
    max-height: 30vh;
    border-radius: 50%;
    margin-top: -200px;
    margin-bottom: 50px;
}

.name-header {
    font-family: $header-font;
    font-weight: $header-weight;
    margin-bottom: 50px;
}

.social-icons {
    display: flex;
    margin-bottom: 30px;
    width: 250px;
    justify-content: space-evenly;
}

.icon {
    transform: scale(1.5, 1.5);
}

.icon:hover {
    transform: scale(1.7, 1.7);
}

.hire-btn, .hire-btn:active, .hire-btn:hover, .hire-btn:focus {
    background-color: $red;
    border: none;
    border-radius: 30px;
    font-weight: 300;
}

.hire-btn:hover {
    transform: scale(1.1, 1.1);
}

.scroll-prompt {
    position: absolute;
    bottom: 5px;
}

@keyframes mouse-scroll {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(0, 15px);
    }
  }

.mouse-wheel {
    animation: 1s mouse-scroll infinite alternate linear;
}

@media (max-height: 800px) {
    .name-header {
        margin-bottom: 20px;
    }
    #portrait {
        max-height: 30vh;
        border-radius: 50%;
        margin-top: -150px;
        margin-bottom: 30px;
    }
    .social-icons {
        margin-bottom: 15px;
    }
}
@import "../../App";

.color-nav {
  background-color: $darkblue;
}

.logo {
  max-width: 125px;
}

@media (max-width: 750px) {
  .logo {
    max-width: 100px;
  }
}

.links {
  font-family: $header-font;
  font-weight: $header-weight;
  font-size: 20px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.link-item {
  color: white;
}

.link-item:hover {
  transform: scale(1.1);
}

$darkblue: #353353;
$yellow: #ffd15c;
$red: #ff4c60;
$gray: #7c90a0;
$main-font: "Work Sans", sans-serif;
$header-font: "Montserrat", sans-serif;
$header-weight: 900;

.app {
  font-family: $main-font;
  font-weight: 300;
}

.section {
  display: flex;
  background-color: $gray;
  color: $yellow;
  padding: 20vh 10vw 0vh;
}

@media (max-width: 850px) {
  .section {
    padding-left: 5vw;
  }
}

.section:last-child {
  padding-bottom: 25vh;
}

.section-header {
  font-family: $header-font;
  color: $yellow;
}
